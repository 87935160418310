<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<nav v-if="!isLoading">
			<h1>{{ $t('my_favourites_artworks') }}</h1>
		</nav>
		
		<div v-if="!isLoading && !selectedArtworks.length" class="error-code">
			{{ $t('no_artworks_category') }}
		</div>
		
		<main v-else-if="!isLoading && selectedArtworks.length">
			<article v-for="artwork in selectedArtworks" :key="artwork.idArtwork"
			         @click="goToArtwork(artwork.idArtwork)">
				<img class="artwork-image" :src="artwork.mainImageUrl" alt="">
				<div class="artwork-info">
					<h2>{{ artwork.author }}</h2>
					<h3>{{ artwork.locale.name }}</h3>
				</div>
			</article>
		</main>
		
		<footer>
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {findLocalization, setFullScreen} from "@/store/helpers";
// import Load from '../../../components/Load.vue';

export default {
	name: "ArtworksKids",
	
	components: {
		// Load,
		Loading
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			selectedArtworks: []
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			studyQuestions: state => state.studyQuestion.studyQuestions,
			studyQuestionsError: state => state.studyQuestion.studyQuestionsError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('my_favourites_artworks');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate kids";
			
			return locationName + ' | ' + museumName;
		},
	},
	
	created() {
		debug.open("ArtworksKids");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'ArtworksKids', params: { idMuseum: this.encrypted } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.selectedArtworks = this.getArtworks();
				})
				.finally(() => {
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		getArtworks() {
			let artworks = [];
			let localStorageTags = localStorage.getItem('selected-tags');
			if(localStorageTags) {
				let tags = JSON.parse(localStorageTags);
				let ids = [];
				for(let tagIndex in tags) {
					for(let artworkIndex in tags[tagIndex].idTags) {
						let artworkId = tags[tagIndex].idTags[artworkIndex];
						if(!ids.includes(artworkId)) {
							ids.push(artworkId);
						}
					}
				}

				for(let i in ids) {
					let coincidences = this.museum.artworks.filter(artwork => artwork.tagIds.includes(ids[i]));
					artworks = artworks.concat(coincidences);
				}
				
				artworks = artworks.filter((v, i) => artworks.indexOf(v) === i); //Removes duplicates
				for(let i in artworks) {
					artworks[i].locale = findLocalization(artworks[i]);
				}
			}
			return artworks;
		},
		
		goBack() {
			setFullScreen();
			this.$router.push({name: 'OptionsKids', params: { idMuseum: this.encrypted} });
		},
		
		goToArtwork(idArtwork) {
			setFullScreen();
			this.$router.push({ name: 'Artwork', params: { idMuseum: this.encrypted, idArtwork: idArtwork } });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors';

.content {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		background-color: white;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	nav {
		position: fixed;
		top: 0;
		height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		border-bottom: 1px solid #cfcfd0;
		z-index: 2;
		
		h1 {
			font-weight: normal;
			font-size: 22px;
			padding: 0 20px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #cfcfd0;
			padding: 5px 20px;
			
			.menu-icon {
				width: 40px;
				height: auto;
			}
		}
	}
	
	main {
		padding: 70px 0 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: white;
		overflow-y: scroll;
		
		article {
			width: 100%;
			position: relative;
			margin-bottom: -3px;
			
			.artwork-image {
				width: 100%;
				height: 150px;
				object-fit: cover;
			}
			
			.artwork-info {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 20px;
				display: flex;
				flex-direction: column;
				
				h2 {
					color: white;
					font-weight: normal;
					font-size: 18px;
				}
				
				h3 {
					color: white;
					font-weight: normal;
					font-size: 22px;
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-white {
					background-color: white;
					color: black;
				}
			}
		}
	}
}
</style>
