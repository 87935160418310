<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		 <loading :active.sync="isLoading"
		         :is-full-page="true"/>
		 
		<nav v-if="!isLoading">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted } }" class="logo">
				<img class="logo-image" :src="museum.topBarLogoImageUrl ? museum.topBarLogoImageUrl : museum.logoImageUrl" alt="">
			</router-link>
		</nav>
		
		<div v-if="!isLoading && sectionsError" class="error-code">
			{{ $t('no_artworks_category') }}
		</div>
		
		<main v-if="!isLoading && !sectionsError" class="main">
			<details v-for="section in sections" :key="section.idSection" class="section">
				<summary class="title" :style="{backgroundColor: section.color}">
					{{ section.locale.name }}
					<i class="mm-icon mm-icon-arrow-right"/>
				</summary>
				<div class="artwork-scroll">
					<div v-for="artwork in section.artworks" :key="artwork.idArtwork"
					     class="artwork-container" @click="goToArtwork(artwork.idArtwork)">
						<img class="artwork-image" :src="artwork.mainImageUrl" alt="">
						<div class="artwork-name-container">
							<p v-if="artwork.locale" class="artwork-name">{{ artwork.locale.name }}</p>
						</div>
					</div>
				</div>
			</details>
		</main>
		
		<footer :style="footerCSSVars">
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
				<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
					<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
					<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {setFullScreen} from "@/store/helpers";
// import Load from '../../../components/Load.vue';

export default {
	name: "Sections",
	
	components: {
	     Loading
		// Load,
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			keyboardCustomization: null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			sections: state => state.section.sections,
			sectionsError: state => state.section.sectionsError,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('sections');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
	},
	
	created() {
		debug.open("Sections");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Sections', params: { idMuseum: this.encrypted } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.getSections();
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
				})
				.finally(() => {
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('section', ['getSections']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		goToArtwork(idArtwork) {
			setFullScreen();
			this.$router.push({ name: 'Artwork', params: { idMuseum: this.encrypted, idArtwork: idArtwork } });
		},
		
		goBack() {
			setFullScreen();
			this.$router.push({name: 'OptionsKids', params: { idMuseum: this.encrypted} });
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors';

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	
	nav {
		height: 75px;
		min-height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #cfcfd0;
		background-color: white;
		
		.logo {
			padding: 5px 20px;
			height: 100%;
			display: flex;
			align-items: center;
			
			.logo-image {
				max-height: 100%;
				max-width: 100%;
			}
		}
		
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #cfcfd0;
			padding: 5px 20px;
			
			.menu-icon {
				width: 40px;
				height: auto;
			}
		}
	}
	
	main {
		background-color: white;
		width: 100%;
		padding: 20px 20px 110px;
		height: 100%;
		flex: 2;
		overflow-y: auto;
		
		.section {
			width: 100%;
			margin-bottom: 20px;
			
			&[open] {
				.mm-icon {
					transform: rotate(90deg);
					margin-right: 3px;
				}
			}
			
			.title {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-transform: uppercase;
				color: $gray-light;
				font-weight: normal;
				padding: 15px 10px;
				box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
				margin-bottom: 10px;
				list-style: none none;
				
				.mm-icon {
					margin-top: -8px;
				}
			}
			
			.artwork-scroll {
				display: flex;
				overflow-x: auto;
				margin: 0 -20px;
				
				.artwork-container {
					min-width: 200px;
					width: 200px;
					height: 200px;
					position: relative;
					
					&:not(:last-child) {
						margin-right: 2px;
					}
					
					&:first-child {
						margin-left: 20px;
					}
					
					.artwork-image {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
					
					.artwork-name-container {
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						background: linear-gradient(transparent 20%, rgba(0, 0, 0, 0.7));
						
						.artwork-name {
							padding: 10px;
							font-size: 16px;
							color: white;
						}
					}
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);
					
					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}
	}
}


</style>
