<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<nav v-if="!isLoading">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted, mode: 'kids'} }" class="logo">
				<img class="logo-image" :src="museum.topBarLogoImageUrl ? museum.topBarLogoImageUrl : museum.logoImageUrl" alt="">
			</router-link>
			
			<router-link :to="{ name: 'Keyboard', params: { idMuseum: encrypted } }" class="icon">
				<i class="mm-icon mm-icon-keyboard" />
			</router-link>
			
			<router-link :to="{ name: 'Game', params: { idMuseum: encrypted } }" class="icon">
				<i class="mm-icon mm-icon-game" />
			</router-link>
		</nav>
		
		<main v-if="!isLoading" class="main">
			<details v-for="section in sections" :key="section.idKidsSection" class="section">
				<summary class="title" :style="{backgroundColor: section.color}">
					{{ section.name }}
					<i class="mm-icon mm-icon-arrow-right"/>
				</summary>
				<ul class="tags">
					<li v-for="tag in section.tags" :key="tag.idKidsTag">
						<input :id="tag.name" v-model="selectedTags" :value="tag" type="checkbox" class="tag-checkbox" name="artworks">
						<label class="tag-name" :for="tag.name">{{ tag.name }}</label>
					</li>
				</ul>
			</details>
			
			<img v-show="selectedTags.length" class="start-button" src="@/assets/kids-start.png" alt="" @click="goToExplore()">
		</main>
		
		<footer :style="footerCSSVars">
			<div class="footer-buttons">
				<button class="button-green" @click="goToSections()">
					<img class="explore-artworks-icon" src="@/assets/icons/all_artworks.png" alt="">  <!-- TODO change this icon -->
				</button>
				<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
					<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
					<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {setFullScreen} from "@/store/helpers";
import KidsSection from "@/models/kids-section";
import KidsTag from "@/models/kids-tag";
// import Load from '../../../components/Load.vue';

export default {
	name: "OptionsKids",
	
	components: {
		// Load,
		Loading
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			sections: [],
			selectedTags: [],
			keyboardCustomization: null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('start');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate kids";
			
			return locationName + ' | ' + museumName;
		},
		
		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
	},
	
	created() {
		debug.open("OptionsKids");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'OptionsKids', params: { idMuseum: this.encrypted } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.finally(() => {
					this.sections = this.getSections();
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		getSections() {
			let sections = [];
			let tags1 = [];
			tags1.push(new KidsTag(1, "Azul",      [598]));
			tags1.push(new KidsTag(2, "Rosa",      [609]));
			tags1.push(new KidsTag(3, "Verde",     [597]));
			tags1.push(new KidsTag(4, "Amarillo",  [608]));
			tags1.push(new KidsTag(5, "Blanco",    [605]));
			
			let tags2 = [];
			tags2.push(new KidsTag(6, "Chimenea",  [606]));
			tags2.push(new KidsTag(7, "Cocina",    [602, 611]));
			tags2.push(new KidsTag(8, "Armadura",  [619]));
			tags2.push(new KidsTag(9, "Sombrero",  [604]));
			tags2.push(new KidsTag(10, "Mesa",     [607]));
			
			let tags3 = [];
			tags3.push(new KidsTag(11, "Jugar con amigos",  [625,592, 600, 612]));
			tags3.push(new KidsTag(12, "Volar una cometa",  [592, 593, 594]));
			tags3.push(new KidsTag(13, "Cocinar",           [602, 611]));
			tags3.push(new KidsTag(14, "Bañarme",           [623]));
			tags3.push(new KidsTag(15, "Recoger flores",    [592, 627]));
			
			let tags4 = [];
			tags4.push(new KidsTag(16, "Caballos",  [615]));
			tags4.push(new KidsTag(17, "Ovejas",    [596]));
			tags4.push(new KidsTag(18, "Pájaros",   [616]));
			tags4.push(new KidsTag(19, "Perros",    [595]));
			
			sections.push(new KidsSection(1, "EXPLORA POR COLORES",    tags1, "#8bb043"));
			sections.push(new KidsSection(2, "EXPLORA POR OBJETOS",    tags2, "#70c4c6"));
			sections.push(new KidsSection(3, "¿DÓNDE PUEDO...",        tags3, "#f17c6f"));
			sections.push(new KidsSection(4, "EXPLORAR POR ANIMALES",  tags4, "#18768e"));
			
			return sections;
		},
		
		goToExplore() {
			setFullScreen();
			localStorage.setItem('selected-tags', JSON.stringify(this.selectedTags));
			this.$router.push({name: 'ArtworksKids', params: { idMuseum: this.encrypted} });
		},
		
		goToSections() {
			setFullScreen();
			this.$router.push({name: 'Sections', params: { idMuseum: this.encrypted} });
		},
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors';

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	
	nav {
		height: 75px;
		min-height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #cfcfd0;
		background-color: white;
		
		.logo {
			padding: 5px 20px;
			height: 100%;
			display: flex;
			align-items: center;
			
			.logo-image {
				max-height: 100%;
				max-width: 100%;
			}
		}
		
		.icon {
			border-left: 1px solid $gray-light;
			padding: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1;
			height: 100%;
			text-decoration: none;
			
			.mm-icon {
				font-size: 42px;
				color: black;
			}
		}
	}
	
	main {
		background: url("~@/assets/kids/background.png");
		width: 100%;
		padding: 20px 20px 110px;
		height: 100%;
		flex: 2;
		overflow-y: auto;
		
		.section {
			width: 100%;
			margin-bottom: 20px;
			
			.title {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-transform: uppercase;
				color: white;
				font-size: 18px;
				padding: 15px 10px;
				list-style: none none;
				
				// Poorly hand-drawn border
				border: 2px solid black;
				border-radius: 255px 15px 225px 15px / 15px 225px 15px 255px;
				
				// Doble inner and outer opacity shadow
				/*
				-moz-box-shadow:    inset 0 0 0 5px rgba(0, 0, 0, 0.2),
									0 0 0 5px rgba(0, 0, 0, 0.2);
				-webkit-box-shadow: inset 0 0 0 5px rgba(0, 0, 0, 0.2),
									0 0 0 5px rgba(0, 0, 0, 0.2);
				box-shadow:         inset 0 0 0 5px rgba(0, 0, 0, 0.2),
									0 0 0 5px rgba(0, 0, 0, 0.2);
				*/
				
				.mm-icon {
					margin-top: -8px;
				}
			}
			
			.tags {
				margin: 20px 0 50px;
				list-style: none;
				
				li {
					margin-bottom: 10px;
					
					.tag-checkbox {
						margin-right: 10px;
					}
				}
			}
		}
		
		.start-button {
			position: fixed;
			bottom: 100px;
			right: 10px;
			width: 80px;
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-green {
					background-color: green;
					color: black;
					
					.explore-artworks-icon {
						height: 50px;
						width: auto;
					}
				}
				
				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);
					
					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}
	}
}


</style>
