<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		<!-- <Load v-if="isLoading"></Load> -->
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/> 
		
		<button class="button-back" @click="goBack()">
			<i class="mm-icon mm-icon-arrow-back"/>
		</button>
		
		<span class="folded-corner"/>
		
		<main v-if="!isLoading && game && game.locale">
			
			<div v-if="index === -1 || index === 6 || (steps[index] && !steps[index].idArtwork)" class="bubble">
				<div class="bubble-content">
					<p v-if="index !== -1 && index !== 6 && steps[index].locale" class="instructions">{{ steps[index].locale.instructions }}</p>
					<p v-if="index === 6" class="instructions">Fin de esta DEMO.</p>
					<audio-player v-if="audioFile"
					              class="audio-player"
					              :file="audioFile"
					              @audioFinished="buttonIsDisabled = false"/>
				</div>
			</div>
			
			<div v-else class="artwork-audio">
				<audio-player v-if="audioFile"
				              class="audio-player"
				              :file="audioFile"
				              :show-timer="false"
				              @audioFinished="buttonIsDisabled = false"/>
			</div>
			
			<div v-if="index !== 6 && (steps[index] && steps[index].idArtwork)" class="pulse-overlay" :class="'pulse' + index"
			     @click="openArtworkModal(steps[index].artwork.mainImageUrl)">
				<lottie :options="pulseAnimationOptions"/>
			</div>
			
			<div v-if="index !== -1 && (steps[index] && !steps[index].idArtwork)" class="artwork" :class="{'right': index === 4}">
				<img class="artwork-image" :src="steps[index+1].artwork.mainImageUrl" alt="">
			</div>
			
			<div class="pet" :class="['pet' + index, { 'right': index === 1}]">
				<img class="pet-image" :src="stepAvatar" alt="">
			</div>
		
		</main>
		
		<div v-if="!isLoading && (gameError || (game && !game.locale))" class="empty-game">
			{{ $t('no_resources') }}
		</div>
		
		<footer v-if="!gameError && game && game.locale">
			<button class="button-start" :disabled="buttonIsDisabled" @click="nextStep()">
				{{ buttonText }}
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import {setFullScreen} from "@/store/helpers";
import RouteVisit from "@/models/routeVisit";
import AudioPlayer from "@/components/AudioPlayer";
const Lottie = () => import("vue-lottie");
import * as animationData from '@/assets/animations/pulse.json';
// import Load from '../../../components/Load.vue';


export default {
	name: "Game",
	
	components: {
		// Load,
		Loading,
		AudioPlayer,
		Lottie
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			index: -1,
			navigate: true,
			pageToGo: 0,
			routeVisit: new RouteVisit(),
			pulseAnimationOptions: {
				animationData: animationData.default,
				animationSpeed: 1
			},
			buttonIsDisabled: true,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			game: state => state.route.route,
			gameError: state => state.route.routeError,
			navigationSections: state => state.navigationSection.navigationSections,
			navigationSectionsError: state => state.navigationSection.navigationSectionsError,
		}),
		
		pageTitle() {
			let locationName = 'Juego'; // TODO translate
			if(this.game?.locale?.title) {
				locationName = this.game.locale.title;
			}
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		steps() {
			return this.game.steps;
		},
		
		audioFile() {
			let audioFile = '';
			
			switch(this.index) {
				case -1:
					audioFile = this.game?.locale?.mainAudioUrl;
					break;
				
				case 0: {
					let step = this.game?.steps[1];
					let stepResources = step?.artwork?.resources;
					audioFile = stepResources?.find((resource) => resource.title === 'kids-instructions-audio.mp3')?.locale?.url;
					break;
				}
				
				case 6: {
					let step = this.game?.steps[this.index];
					let stepResources = step?.artwork?.resources;
					audioFile = stepResources?.find((resource) => resource.title === 'kids-instructions-audio.mp3')?.locale?.url;
					break;
				}
				
				default:
					if(this.game?.steps[this.index].idArtwork) {
						// If the step is an artwork
						let step = this.game.steps[this.index];
						let stepResources = step?.artwork?.resources;
						audioFile = stepResources?.find((resource) => resource.title === 'kids-artwork-audio.mp3')?.locale?.url;
					} else {
						// If the step is an instruction
						let step = this.game?.steps[this.index + 1];
						let stepResources = step?.artwork?.resources;
						audioFile = stepResources?.find((resource) => resource.title === 'kids-instructions-audio.mp3')?.locale?.url;
					}
					
					break;
			}
			
			return audioFile;
		},
		
		buttonText() {
			let text = '';
			
			if(this.buttonIsDisabled) {
				text = 'Espera hasta que acabe el audio';
			} else {
				switch (this.index) {
					case -1:
						text = 'Empezar a jugar';
						break;
					
					case 6:
						text = 'Volver a la pantalla principal';
						break;
					
					default:
						text = (this.steps[this.index]?.idArtwork)
							? 'Pulsa aquí cuando lo localices'
							: 'Ir a la siguiente pista';
						break;
				}
			}
			
			return text;
		},
		
		cssVars() {
			let backgroundImage = '';
			
			switch(this.index) {
				case -1:
					backgroundImage = this.game?.mainImageUrl;
					break;
				
				case 6:
					backgroundImage = this.game?.steps[this.index]?.artwork?.mainImageUrl;
					break;
				
				default:
					if(this.game?.steps[this.index].idArtwork) {
						let step = this.game.steps[this.index];
						let stepResources = step?.artwork?.resources;
						backgroundImage = stepResources?.find((resource) => resource.title === 'kids-artwork-background.jpg')?.url;
					} else {
						backgroundImage = this.game?.steps[this.index + 1]?.locationImageUrl;
					}
					break;
			}
			
			return {
				'--background-image': 'url(' + (backgroundImage ? backgroundImage : '') + ')',
			};
		},
		
		stepAvatar() {
			return require('@/assets/kids/game-avatars/avatar' + this.index + '.png');
		}
	},
	
	created() {
		debug.open("Game");
		debug.log("Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'Game', params: { idMuseum: this.encrypted } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(async () => {
					await this.findGame();
					
					this.setRouteVisit();
					
					debug.close();
					await this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
					
					if(this.navigate && this.$refs.carousel && this.$route.query.artwork) {
						this.navigate = false;
						let step = this.steps.find((step) => step.idArtwork === parseInt(this.$route.query.artwork));
						if(step) {
							this.index = parseInt(step.index);
							this.pageToGo = parseInt(step.index);
						}
					}
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('route', ['findGame']),
		...mapActions('navigationSection', ['getNavigationSections']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		createNewRouteVisit() {
			localStorage.removeItem('current-artwork-visit');
			this.routeVisit = new RouteVisit(this.idRoute, new Date(), new Date());
			this.$store.commit('app/' + mutationTypes.ADD_ROUTE_VISIT, this.routeVisit);
		},
		
		setRouteVisit() {
			debug.open("setRouteVisit");
			
			let currentRouteVisit = localStorage.getItem('current-route-visit');
			try {
				currentRouteVisit = JSON.parse(currentRouteVisit);
				if(currentRouteVisit.idRoute === this.idRoute) {
					debug.log("Continuing with the visit", currentRouteVisit);
					this.routeVisit.parseJSON(currentRouteVisit);
				} else {
					debug.log("There is a visit ongoing, but it is not for this route, finishing it and creating a new one...");
					this.createNewRouteVisit();
				}
			} catch(e) {
				debug.log("There is no visit ongoing, creating one...");
				this.createNewRouteVisit();
			}
			
			localStorage.setItem('current-route-visit', JSON.stringify(this.routeVisit));
			
			debug.close();
		},
		
		openArtworkModal(artworkImage) {
			this.$swal({
				padding: '25px 20px 20px 20px',
				heightAuto: false,
				imageUrl: artworkImage,
				imageWidth: '100%',
				imageHeight: '100%',
				imageAlt: 'Custom image',
			});
		},
		
		nextStep() {
			if(this.index < 6) {
				this.index++;
			} else {
				this.goBack();
			}
			this.buttonIsDisabled = true;
		},
		
		goBack() {
			this.$store.commit('app/' + mutationTypes.UPDATE_LAST_ROUTE_VISIT);
			setFullScreen();
			this.$router.push({ name: 'OptionsKids', params: { idMuseum: this.encrypted } });
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../../theme/colors';

.content {
	width: 100%;
	height: 100%;
	background: var(--background-image) no-repeat;
	background-position: center center;
	background-size: cover;
	
	.empty-game {
		background-color: white;
		padding: 80px 20px 100px 20px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	.button-back {
		position: absolute;
		top: 10px;
		left: 10px;
		background-color: transparent;
		border: none;
		z-index: 10;
		
		.mm-icon {
			font-size: 24px;
		}
	}
	
	.folded-corner {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 0 0 15px 0;
		
		z-index: 1;
		
		-webkit-box-shadow: 2px 1px 4px black, 0 1px 1px rgba(0,0,0,0.1), -1px 1px 1px rgba(0,0,0,0.1);
		-moz-box-shadow:    2px 1px 4px black, 0 1px 1px rgba(0,0,0,0.1), -1px 1px 1px rgba(0,0,0,0.1);
		box-shadow:         2px 1px 4px black, 0 1px 1px rgba(0,0,0,0.1), -1px 1px 1px rgba(0,0,0,0.1);
		
		background: -moz-linear-gradient(315deg, white 40px, hsla(260, 5%, 75%, 0.5) 35px);
		background: -o-linear-gradient(315deg, white 40px, hsla(260, 5%, 75%, 0.5) 35px);
		background: -webkit-linear-gradient(315deg, white 40px, hsla(260, 5%, 75%, 0.5) 35px);
	}
	
	main {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		overflow: hidden;
		
		.bubble {
			width: 100%;
			position: fixed;
			top: 100px;
			display: flex;
			justify-content: center;
			z-index: 10;
			
			.bubble-content {
				background-color: orange;
				width: 80%;
				padding: 20px;
				border-radius: 20px;
				margin-bottom: 20px;
				position: relative;
				
				&::before {
					content: "";
					width: 0;
					height: 0;
					position: absolute;
					border-left: 12px solid transparent;
					border-right: 24px solid orange;
					border-top: 12px solid orange;
					border-bottom: 20px solid transparent;
					left: 40%;
					bottom: -24px;
				}
				
				.instructions {
					color: white;
					font-size: 16px;
					margin-bottom: 20px;
				}
				
				.audio-player {
				
				}
			}
		}
		
		.artwork-audio {
			background-color: orange;
			display: flex;
			justify-content: center;
			width: 250px;
			padding: 10px 20px 2px;
			border-radius: 10px;
			position: fixed;
			bottom: 130px;
			left: 20px;
			z-index: 2;
		}
		
		.pulse-overlay {
			position: absolute;
			left: 50%;
			top: 40%;
			width: 50px;
			height: 50px;
			z-index: 10;
			
			&.pulse1 {
				left: 25%;
				top: 45%;
			}
			
			&.pulse3 {
				left: 20%;
				top: 30%;
			}
			
			&.pulse5 {
				left: 23%;
				top: 45%;
			}
		}
		
		.artwork {
			width: 100%;
			display: flex;
			padding-left: 50px;
			padding-top: 130px;
			
			&.right {
				justify-content: flex-end;
				padding-left: 0;
				padding-right: 50px;
			}
			
			.artwork-image {
				max-width: 150px;
				height: auto;
				max-height: 150px;
			}
		}
		
		.pet {
			position: fixed;
			bottom: 0;
			z-index: 1;
			
			&.right {
				-webkit-transform: scaleX(-1);
				transform: scaleX(-1);
			}
			
			.pet-image {
				width: 300px;
			}
			
			&.pet-1 {
				bottom: -200px;
				right: -80px;
				
				.pet-image {
					width: 350px;
				}
			}
			
			&.pet0 {
				bottom: -80px;
				right: 0;
				
				.pet-image {
					width: 270px;
				}
			}
			
			&.pet1 {
				bottom: -150px;
				right: -20px;
			}
			
			&.pet2 {
				bottom: -200px;
				right: -50px;
			}
			
			&.pet3 {
				bottom: -100px;
				right: -50px;
			}
			
			&.pet4 {
				bottom: -200px;
				left: -20px;
			}
			
			&.pet5 {
				bottom: -180px;
				right: -50px;
			}
			
			&.pet6 {
				bottom: -100px;
				left: 20%;
				
				.pet-image {
					width: 270px;
				}
			}
		}
	}
	
	footer {
		width: 100%;
		display: flex;
		justify-content: center;
		justify-items: center;
		position: fixed;
		bottom: 0;
		z-index: 100;
		
		.button-start {
			text-transform: uppercase;
			position: relative;
			margin-bottom: 50px;
			width: 70%;
			height: 60px;
			border: 1px solid $green;
			font-size: 16px;
			text-align: center;
			color: white;
			background: $green;
			border-radius: 4px;
			box-shadow: 0 0 30px rgba(0,0,0,.15) inset,
			0 6px 10px rgba(0,0,0,.15);
			
			&::before, &::after {
				content: '';
				position: absolute;
				z-index: -1;
				top: 32px;
				width: 8%;
				height: 0;
				border: 30px solid $green;
				border-bottom-color: $green;
			}
			
			&::before {
				left: -50px;
				border-right: 20px solid #791;
				border-left: 30px solid transparent;
				transform: rotate(-5deg);
			}
			
			&::after {
				right: -50px;
				border-left: 20px solid #791;
				border-right: 30px solid transparent;
				transform: rotate(5deg);
			}
			
			&:disabled {
				border: 1px solid $disabled-green;
				background: $disabled-green;
				
				&::before, &::after {
					border: 30px solid $disabled-green;
					border-bottom-color: $disabled-green;
				}
				
				&::before {
					left: -50px;
					border-right: 20px solid #791;
					border-left: 30px solid transparent;
					transform: rotate(-5deg);
				}
				
				&::after {
					right: -50px;
					border-left: 20px solid #791;
					border-right: 30px solid transparent;
					transform: rotate(5deg);
				}
			}
		}
	}
}
</style>
